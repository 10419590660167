

.NavHome_Link{

  color: black;

}
.NavHome_Link:hover{
  color:#865945 ;
} 
.logo_home{
  width: 80px;
  height: 80px;
}
.NaveHome_media{
  display: flex;
  justify-content: center;
}
.ms-9{
  margin-inline-start: 150px;
}
@media  screen and (max-width: 1000px) {
  .ms-9{
    margin-inline-start:0px;
  }
  .NaveHome_media{
   
display: block;
  }
  .NavHome_Link{

    color: white;
  
  }
  .NavHome_Link:hover{
    color:#000000 ;
  } 

.NavHome_btn{
 color: white;
}

}


/* 
.Navbtn_Active{
  
  background-color: #EADBCB;
  border-style: unset;
  color: black;
  font-weight: bold;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

.Nav_btn:hover{
    background-color: #EADBCB;

}
.px-8{
  padding-left:  80px;
  padding-right: 80px;
}
.nav_icon-contain{   
  
  height: 50px;
  width: 120px;
 
}

@media screen and (min-width: 991px) {
  /* Add your CSS styles for large screens here */
  /* .w-media{
    width: 100%;
  
  }
  .m-media{
    margin-left: 70px ;
  
  }
}
@media screen and (max-width: 991px) {
  /* Add your CSS styles for large screens here */
  /* .w-media{
    margin-left: 0px;
    width: 50%;
    margin-top: 10px;
  }
  .m-media{
    margin-left: 0px ;
  
  }
}
.btn_shadow{
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
} */ 
