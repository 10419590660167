.logo_footer{
    width: 200px;
    height: 200px;
  }
  .footer_Links{
    color: rgb(255, 255, 255);
      }
  .footer_Links:hover{
color: black;
  }
  .footer_line{
    margin-top: 40px;
    width: 100%;
    height: 1px;
     background-color: white;
  }
  .cobyright_underline{
    text-underline-offset: 8px;
  }
  @media  screen and (min-width: 719px) {
    .h4_footer_media h4 {
      font-size: large;
      font-weight: normal;
    }
    .h4_footer_media h2 {
   font-size: larger;
    }
    .h4_footer_media li {
      font-size: large;
          }
  }
  @media  screen and (max-width: 720px) {
    .h4_footer_media h4 {
font-size: 12px;
font-weight: normal;
    }
    .h4_footer_media h2 {
      font-size: small;
       }
    .h4_footer_media li {
      font-size: 12px;
      
          }
  }
  