.sec2_sell_bg{
    background-image: url("../../photos/Rectangle\ 2771.png") , url("../../photos/5\ Ways\ to\ Start\ a\ Lucrative\ Real\ Estate\ Side\ Hustle\ _\ Entrepreneur\ 1.png") ;
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
}
.sec2-sell_input{

    height: 50px;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 2px;
    border-color: white;
    border-radius: 6px;
}
.sec2-sell_input_mass{

    height: 70px;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 2px;
    border-color: white;
    border-radius: 6px;
  }
  .sec2-sell_input::placeholder{
    padding-left: 10px;
    color: white;
    
    }
    .sec2-sell_input_mass::placeholder{
      color: white;
    }

.sec2-sell_select {

    height: 50px;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 2px;
    border-color: rgb(255, 255, 255);
    border-radius: 6px;
  }    

  .sec2-sell_select option {
    background-color: rgb(53, 53, 53);
  }

  .upload__image-wrapper{
    margin-top: 20px;
    
  }
  .upload__image-wrapper button{
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border-style: solid;
    border-radius: 5px;
    border-color: white;
    margin-top: 10px;
  }