@media screen and (max-width: 960px) {
  .display_properities_hide {
    display: none;
  }
  .sec1_properities-img {
    display: none;
  }
  .text_properities_center_media {
    text-align: center;
    margin-inline-start: 35px;
  }
}

@media screen and (max-width: 720px) {
  .sec1_properities_text {
    color: #865945;
  }
  .sec1_properities_btn {
    background-color: #d9d9d9;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 100px;
    height: 60px;
    font-weight: bold;
  }
  .sec1_properities_btn:hover {
    background-color: #865945;
    color: white;
    height: 80px;
  }
 
  .New_Projects_btn {
    background-image: url("../../photos/new_projects.jpeg");
    background-size: contain;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 320px;
    height: 320px;
    font-weight: bold;
    transition: width 0.5s, height 0.5s;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .vila_btn {
    background-image: url("../../photos/Villa.jpeg");
    background-size: contain;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 320px;
    height: 320px;
    font-weight: bold;
    transition: width 0.5s, height 0.5s;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .apartment_btn {
    background-image: url("../../photos/Apartment.jpeg");
    background-size: contain;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 320px;
    height: 320px;
    font-weight: bold;
    transition: width 0.5s, height 0.5s;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .office_btn {
    background-image: url("../../photos/Office.jpeg");
    background-size: contain;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 320px;
    height: 320px;
    font-weight: bold;
    transition: width 0.5s, height 0.5s;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .shope_btn {
    background-image: url("../../photos/Shop.jpeg");
    background-size: contain;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 320px;
    height: 320px;
    font-weight: bold;
    transition: width 0.5s, height 0.5s;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .coastal_btn {
    background-image: url("../../photos/coastal.jpeg");
    background-size: contain;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 320px;
    height: 320px;
    font-weight: bold;
    transition: width 0.5s, height 0.5s;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .Efid_btn {
    background-image: url("../../photos/Efid.jpeg");
    background-size: cover;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 150px;
    height: 140px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
  }
  .Soliman_btn {
    background-image: url("../../photos/Soliman.jpeg");
    background-size: cover;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 150px;
    height: 140px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
  }
  .TE_btn {
    background-image: url("../../photos/TE.jpeg");
    background-size: cover;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 150px;
    height: 140px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
  }
  .Almarasem_btn {
    background-image: url("../../photos/Almarasem.jpeg");
    background-size: cover;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 150px;
    height: 140px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
  }
  .Frimo_btn {
    background-image: url("../../photos/Frimo.jpeg");
    background-size: cover;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 150px;
    height: 140px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
  }
  .Alashraf_btn {
    background-image: url("../../photos/Alashraf.jpeg");
    background-size: cover;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 150px;
    height: 140px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
  }
  .btn-sec3 {
    margin-bottom: 15px;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 150px;
    height: 50px;
    font-weight: bold;
  }
}
@media screen and (min-width: 719px) {
  .sec1_properities-img {
  }
  .sec1_properities_text {
    color: #865945;
  }
  .sec1_properities_btn {
    background-color: #d9d9d9;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 100px;
    height: 60px;
    font-weight: bold;
  }
  .sec1_properities_btn:hover {
    background-color: #865945;
    color: white;
  }

  .New_Projects_btn {
    background-image: url("../../photos/new_projects.jpeg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 250px;
    height: 270px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .vila_btn {
    background-image: url("../../photos/Villa.jpeg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 250px;
    height: 270px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .apartment_btn {
    background-image: url("../../photos/Apartment.jpeg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 250px;
    height: 270px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .office_btn {
    background-image: url("../../photos/Office.jpeg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 250px;
    height: 270px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .shope_btn {
    background-image: url("../../photos/Shop.jpeg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 250px;
    height: 270px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .coastal_btn {
    background-image: url("../../photos/coastal.jpeg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 250px;
    height: 270px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
    display: flex;
    justify-content: center;
    align-items: end;
  }
  .Efid_btn {
    background-image: url("../../photos/Efid.jpeg");
    background-size: cover;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 250px;
    height: 270px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
  }
  .Soliman_btn {
    background-image: url("../../photos/Soliman.jpeg");
    background-size: cover;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 250px;
    height: 270px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
  }
  .TE_btn {
    background-image: url("../../photos/TE.jpeg");
    background-size: cover;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 250px;
    height: 270px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
  }
  .Almarasem_btn {
    background-image: url("../../photos/Almarasem.jpeg");
    background-size: cover;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 250px;
    height: 270px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
  }
  .Frimo_btn {
    background-image: url("../../photos/Frimo.jpeg");
    background-size: cover;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 250px;
    height: 270px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
  }
  .Alashraf_btn {
    background-image: url("../../photos/Alashraf.jpeg");
    background-size: cover;
    background-position: center center;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 250px;
    height: 270px;
    font-weight: bold;
    transition: width 0.3s, height 0.3s;
  }
  .btn-sec3 {
    margin-bottom: 15px;
    border-radius: 6px;
    border-style: solid;
    border-width: 0px;
    width: 150px;
    height: 50px;
    font-weight: bold;
  }
}
