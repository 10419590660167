*{
  font-family: simplified;
}
.ms-0 {
  margin-inline-start: 0;
}

.ms-1 {
  margin-inline-start: 0.25rem;
}

.ms-2 {
  margin-inline-start: 0.5rem;
}

.ms-3 {
  margin-inline-start: 1rem;
}

.ms-4 {
  margin-inline-start: 1.5rem;
}

.ms-5{
  margin-inline-start: 3rem !important;
}
.me-0 {
  margin-inline-end: 0;
}

.me-1 {
  margin-inline-end: 0.25rem;
}

.me-2 {
  margin-inline-end: 0.5rem;
}

.me-3 {
  margin-inline-end: 1rem;
}

.me-4 {
  margin-inline-end: 1.5rem;
}

.me-5 {
  margin-inline-end: 3rem;
}

.ps-0 {
  padding-inline-start: 0;
}

.ps-1 {
  padding-inline-start: 0.25rem;
}

.ps-2 {
  padding-inline-start: 0.5rem;
}

.ps-3 {
  padding-inline-start: 1rem;
}

.ps-4 {
  padding-inline-start: 1.5rem;
}

.ps-5 {
  padding-inline-start: 3rem;
}
.pe-0 {
  padding-inline-end: 0;
}

.pe-1 {
  padding-inline-end: 0.25rem;
}

.pe-2 {
  padding-inline-end: 0.5rem;
}

.pe-3 {
  padding-inline-end: 1rem;
}

.pe-4 {
  padding-inline-end: 1.5rem;
}

.pe-5 {
  padding-inline-end: 3rem;
}

.content {
    margin-left: 0;
    transition: margin-left 0.3s;
  }
  
  .content.shifted {
    margin-left: 250px;
  }

.ms-9{
  margin-inline-start: 150px;
}
.m-78{
  margin-inline-start: 78px;
  margin-inline-end: 78px;
  
}
.p-78{
  padding-inline-start: 78px;
  padding-inline-end: 78px;
}



.m-nav-78{
 padding-inline-start: 78px;
 padding-inline-end: 78px;
}
.whatssapp_icon{
  font-size: 50px;
  color: green;
}
.floating_whatssapp{
  position: fixed;
  right: 30px;
  bottom:50px ;
  z-index: 1;

}
.floating_whatssapp_ar{
  position: fixed;
  left: 30px;
  bottom:50px ;
  z-index: 1;
}
@media  screen and (max-width: 720px) {
  .m-nav-78{
    padding-inline-start: 12px;
    padding-inline-end: 12px;
  }
  .m-78{
    margin-inline-start: 25px;
    margin-inline-end: 25px;
  }
  .ms-9{
    margin-inline-start: 0px;
  }

}


