.modal-image {
  cursor: pointer;
  width: 100%;
  height: 100%;
  max-height: 100px !important;
  object-fit: cover;
}

.__react_modal_image__modal_content {
margin-top: 100px;
max-height: 500px;

}
@media screen and (min-width: 992px) {
  
.Details_requist{
    
    
    height: 340px;
    width: 350px;
    border-style: solid;
    border-color: rgba(97, 97, 97, 0.644);
    border-width: 2px;
    border-radius: 5px;
}
.Details_requist input{
    width: 300px;
    height: 40px;
    border-radius: 5px;
    border-style: solid;
    border-color: rgba(97, 97, 97, 0.644);
    border-width: 1px;
}
.Details_request_btn{
    width: 200px;
    height: 40px;
    color: white;
    border-radius: 5px;
    border-style: solid;
    border-color: rgba(97, 97, 97, 0.644);
    border-width: 1px;
    background-color: #003075;
}
.Details_card .card{
    width: 230px;
    height: 300px;
    border-width: 0px;
    background: linear-gradient(180deg, rgba(229,229,229,1) 1%, rgb(255, 255, 255) 100%);
  }
  .Details_card .card-body{
    width: 230px;
    height: 300px;
    background: linear-gradient(180deg, rgba(229,229,229,1) 1%, rgb(255, 255, 255) 100%);
  border-bottom-left-radius:10px ;
  border-bottom-right-radius:10px ;
    box-shadow: 10px 10px 30px  #e5e5e5;
  }

  .Details_card p {
    font-size: 12px;
  }

}
@media screen and (max-width: 993px) {
    /* .details_media_center{
        text-align: center;
    } */
 
    .Details_requist{
        
        
        height: 340px;
        width: 250px;
        border-style: solid;
        border-color: rgba(97, 97, 97, 0.644);
        border-width: 2px;
        border-radius: 5px;
    }
    .Details_requist input{
        width: 200px;
        height: 40px;
        border-radius: 5px;
        border-style: solid;
        border-color: rgba(97, 97, 97, 0.644);
        border-width: 1px;
    }
    .Details_request_btn{
        width: 200px;
        height: 40px;
        color: white;
        border-radius: 5px;
        border-style: solid;
        border-color: rgba(97, 97, 97, 0.644);
        border-width: 1px;
        background-color: #003075;
    }
    .Details_card .card{
        width: 300px;
        height: 300px;
        border-width: 0px;
        background: linear-gradient(180deg, rgba(229,229,229,1) 1%, rgb(255, 255, 255) 100%);
      }
      .Details_card .card-body{
        width: 300px;
        height: 300px;
        background: linear-gradient(180deg, rgba(229,229,229,1) 1%, rgb(255, 255, 255) 100%);
      border-bottom-left-radius:10px ;
      border-bottom-right-radius:10px ;
        box-shadow: 10px 10px 30px  #e5e5e5;
      }
    
      .Details_card p {
        font-size: 12px;
      }
    }




    .thumbnail {
      width: 50%;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
   
    }
    
   
    
    .modal {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background: rgba(0, 0, 0, 0.8);
      margin-top: 20px;
    }
    
    .overlay {
      background-color: rgba(0, 0, 0, 0.8);
    }
    .modal-content {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
    }
    .modal-photo {
      max-width: 70%;
      max-height: 100%;
      transition: transform 0.3s;
    }
    
  