.Nav_Link{

    color: black;
  
  }
  .Nav_Link:hover{
    color:#865945 ;
  }
  .languag_btn{

    color: black;
  
  }
  .languag_btn:hover{
    color:#000000 ;
  }
  .navbar_bg{
    background-color:#DFE0DF ;
    position: fixed ;
    width: 100%;
    top: 0px;
    z-index: 10000;
  }
 
  .logo{
    width: 70px;
    height: 70px;
  }
