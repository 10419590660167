.ContactUs_text h2{
    color:#865845 ;
}
@media screen and (max-width: 1140px) {
    .ContactUs_fs{
        font-size: 60px;
    }
    .ContactUs_bg{
        background-image: url("../../photos/Contactus_bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 350px;
    }
    .ContactUs_text_center{
        text-align: center;
    }
    
    .sec2-contact_input{
        width: 100%;
        height: 50px;
        background-color: rgba(0, 0, 0, 0);
        border-style: solid;
        border-width: 2px;
        border-color: rgba(0, 0, 0, 0.281);
        border-radius: 6px;
    }
    .sec2-contact_input_mass{
        height: 70px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0);
        border-style: solid;
        border-width: 2px;
        border-color: rgba(0, 0, 0, 0.281);
        border-radius: 6px;
      }
      .sec2-contact_input::placeholder{
        padding-inline-start: 10px;
        color: black;
        
        }
        .sec2-contact_input_mass::placeholder{
            padding-inline-start: 10px;
          color: black;
        }
    
    .contactUs_bn{
        background-color: #003075;
        color:#ffff ;
        border-radius: 8px;
        font-size: 30px;
        border-style: solid;
        border-width: 0px;
    }
    
}
@media screen and (min-width: 1139px) {
    .ContactUs_fs{
        font-size: 60px;
    }
    .ContactUs_bg{
        background-image: url("../../photos/Contactus_bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
    }
    .sec2-contact_input{
        width:400px;
        height: 50px;
        background-color: rgba(0, 0, 0, 0);
        border-style: solid;
        border-width: 2px;
        border-color: rgba(0, 0, 0, 0.281);
        border-radius: 6px;
    }
    .sec2-contact_input_mass{
        height: 70px;
        width:400px;
        background-color: rgba(0, 0, 0, 0);
        border-style: solid;
        border-width: 2px;
        border-color: rgba(0, 0, 0, 0.281);
        border-radius: 6px;
      }
      .sec2-contact_input::placeholder{
        padding-inline-start: 10px;
        color: black;
        
        }
        .sec2-contact_input_mass::placeholder{
            padding-inline-start: 10px;
          color: black;
        }
    
    .contactUs_bn{
        background-color: #003075;
        color:#ffff ;
        border-radius: 8px;
        font-size: 30px;
        border-style: solid;
        border-width: 0px;
    }
    
}