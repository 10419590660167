
@media screen and (max-width: 992px) {
    .services_bg{
        background-image: url("../../photos/Backgrouond.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 350px;
    }
    .services_fs{
        font-size: 40px;
    }

  }
  .service_cards .card-body{
    width: 350px;
    height: 450px;
    background: linear-gradient(180deg, rgba(229,229,229,1) 1%, rgb(255, 255, 255) 100%);
    border-radius: 10px;
    box-shadow: 10px 10px 30px  #e5e5e5;
    
}
.service_cards .card{
    width: 350px;
    height: 500px;
    border-radius: 10px;
}
.Servies_btn{
    background-color: #003075;
    color: white;
    border-radius: 5px;
    border-width: 0px;
    font-size: 20px;
}
@media screen and (min-width: 991px) {
    .services_bg{
        background-image: url("../../photos/Backgrouond.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 100vh;
    }

    .services_fs{
        font-size: 70px;
    }
    .service_cards .card-body{
        width: 450px;
        height: 450px;
        background: linear-gradient(180deg, rgba(229,229,229,1) 1%, rgb(255, 255, 255) 100%);
        border-radius: 10px;
          box-shadow: 10px 10px 30px  #e5e5e5;
        
    }
    .service_cards .card{
        width: 450px;
        height: 450px;
        border-radius: 10px;
    }
}