*{
  word-break: break-word;
}
input:focus {
  outline: none; 
  }
.left_to_right{
    direction: ltr;
}
.right-to-left{
    direction: rtl;
}

.mb6{
  margin-bottom: 100px;
}

@media  screen and (max-width: 720px) {
 
  
  .sec2-bg{
    background-color: transparent;
  
    margin-top: -50px;
  }
  .sec2-bg2{
    background-color: #dfe0df;
    border-radius: 10px;
  }
  .sec2_input{
    background-color:#dfe0df ;
    border-radius: 5px;
    border-style: solid;
    border-color: rgb(168, 168, 168);
    
  }
  .sec2_input:focus{
    border-color: rgb(107, 107, 107);
  }
  
  .sec2-bg2 i {
    position: absolute;
  }
  
  .icon {
    padding: 15px;
    color: rgb(6, 0, 15);
    margin-left: -60px;
  
  }
  .sec2_button{
    background: none;
   
    border: none;
  
   
  }
  .sec2_home_ms{
    text-align: center;
  }
  .form-control{
    
  }
  .sec2_search{
    background-color: #865945;
  font-size: 30px;
  
    
    
  }
  .Services_d_block{
    display: block;
  }
  .sec3_filtration{

  color: black;
  }
  .sec3_filtration:hover{
    color: #865945;
  }
  
  .properities_text_media{
    font-size: 15px ;
    font-weight: bolder;
  }
  .sec_card_text{
    color: black;
  }
  .sec_card_text:hover{
    color: #ffffff;
  }
  .card-img-top{
    width: 270px;
    height: 250px;
  }
  .home_sell_card .card{
    overflow: hidden;
    margin-top: 100px;
    height: 230px;
    border-width: 0px;
    background: linear-gradient(180deg, rgba(229,229,229,1) 1%, rgb(255, 255, 255) 100%);
    font-size: 8px;
  }
  .home_sell_card .card-body{
    height: 230px;
    background: linear-gradient(180deg, rgba(229,229,229,1) 1%, #865945 100%);
    border-bottom-left-radius:10px ;
    border-bottom-right-radius:10px ;
    box-shadow: 10px 10px 30px  #e5e5e5;
    font-size: 12px;
    
  }
  .home_sell_card .card-body h5{
    font-size: 15px;
  }
  .home_sell_card .img_card{
    width: 100%;
    height:100%;
    transition: width 0.3s ease, height 0.3s ease;
  }
  .home_sell_card .img_card:hover{
    width: 105%;
    height:110%;
  }
  .display_hide{
    display: none;
  }
  .text_center_media{
    text-align: center;
    
  }
  .sec4_text_color{
    color: #865945;
  
  }
  .sec4_fontsize{
    font-size: 18px;
    font-weight: 500;
  }
  .sec4_img{
    width: 300px;
    height: 300px;
  }
  .sec5_hight{
    height: 400px;
  }
  .home_cards .card-body{
    width: 350px;
    height: 450px;
    background: linear-gradient(180deg, rgba(229,229,229,1) 1%, rgb(255, 255, 255) 100%);
    border-radius: 10px;
      box-shadow: 10px 10px 30px  #e5e5e5;
      
  }
  .home_cards .card{
    width: 350px;
      height: 450px;
      border-radius: 10px;
  }
  
  .sec5_icons{
    width: 100px;
    height:100px;
    border-radius: 50%;
    background-color: #865945;
    font-size: 60px;
    margin: auto;
    margin-top: -50px;
  }
  .sec5_button{
    font-size: 25px;
    border-radius: 5px;
    border-style: solid;
    border-color: #865845 ;
  background-color:#865845 ;
  
  }
  .hidden_text{
    visibility: hidden;
  }
  .sec6_home_text li{
    line-height: 10px;
    font-weight: bold;
  color:#865845 ;
  font-size: 10px;
  }
  .sec6_home_text span{
    color:#000000 ;
    line-height: 10px;
    }
  .sec6_text{
   position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #865845;
  }
  .sec6_line{
    position: absolute;
    right: 6px;
    width: 2px;
    height: 50px;
    background-color: #865845;
  }
  .sec-7_bg{
    background-image: url("../../photos/Background\ _2\(1\).png");
    background-size:100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
  
  }
  .sec7_input{
    display: block;
    width: 100%;
    height: 50px;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 2px;
    border-color: white;
    border-radius: 6px;
  }
  .sec7_input_message{
    display: block;
    width: 100%;
    height: 70px;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 2px;
    border-color: white;
    border-radius: 6px;
  }
  .sec7_input::placeholder{
    padding-left: 10px;
    color: white;
  
  }
  .sec7_input_message::placeholder{
    color: white;
  }
  .sec7_btn{
    background-color: #DFE0DF;
    color:#865945 ;
    border-radius: 8px;
    font-size: 36px;
    border-style: solid;
    border-width: 0px;
  }
  .sec8_bg{
    background-color: #865945;
  
  }
  .input-group-append{
    position: absolute;
    right: 25px;
    top: 5px;
  }
  .input-group-append_ar{
    position: absolute;
    left: 25px;
    top: 5px;
  }
  
  
}
@media  screen and (min-width: 719px) {
  .sec1_home_text_center{
    margin-inline-start: 50px;
  }
 
  .sec2-bg{
    background-color: transparent;
   
    margin-top: -50px;
  }
  .sec2-bg2{
    background-color: #dfe0df;
    border-radius: 10px;
 
  }
.sec2_home_ms{
  margin-inline-start: 30px;
  
}
  .sec2_input{
    background-color:#dfe0df ;
    border-radius: 5px;
    border-style: solid;
    border-color: rgb(168, 168, 168);
    
  }
  .sec2_input:focus{
    border-color: rgb(107, 107, 107);
  }
  
  .sec2-bg2 i {
    position: absolute;
  }
  
  .icon {
    padding: 15px;
    color: rgb(6, 0, 15);
    
    
    margin-left: -60px;
  
  }
  .sec2_button{
    background: none;
   
    border: none;
  
   
  }
  .form-control{
    
  }
  .sec2_search{
    background-color: #865945;
  font-size: 30px;
  
    
    
  }
  .Services_d_block{
    display: flex;
  }
  .sec3_filtration{
  color: black;
  }
  .sec3_filtration:hover{
    color: #865945;
  }
  
  .sec_card_text{
    color: black;
    font-size: 20px;
  }
  .sec_card_text:hover{
    color: #ffffff;
  }
  .home_sell_card .card{
    overflow: hidden;
    margin-bottom: 50px;
    margin-top: 100px;
    width: 300px;
    height: 400px;
    border-width: 0px;
    background: linear-gradient(180deg, rgba(229,229,229,1) 1%, rgb(255, 255, 255) 100%);
    font-size: 17px;
  }
  .home_sell_card .card-body{
    width: 300px;
    height: 400px;
    background: linear-gradient(180deg, rgba(229,229,229,1) 1%, #865945 100%);
    border-bottom-left-radius:10px ;
    border-bottom-right-radius:10px ;
    box-shadow: 10px 10px 30px  #e5e5e5;
    font-size: 20px;
  }
  .home_sell_card .img_card{
    width: 100%;
    height:100%;
    transition: width 0.3s ease, height 0.3s ease;
  }
  .home_sell_card .img_card:hover{
    width: 105%;
    height:110%;
  }
  .sec4_text_color{
    color: #865945;
  }
  .sec4_fontsize{
    font-size: 18px;
    font-weight: 500;
  }
  .sec4_img{
    width: 300px;
    height: 300px;
  }
  .sec5_hight{
    height: 400px;
  }
  .home_cards .card-body{
    width: 350px;
    height: 450px;
    background: linear-gradient(180deg, rgba(229,229,229,1) 1%, rgb(255, 255, 255) 100%);
    border-radius: 10px;
      box-shadow: 10px 10px 30px  #e5e5e5;
  }
  .home_cards .card{
    width: 350px;
      height: 450px;
      border-radius: 10px;
  }
  .sec5_icons{
    width: 100px;
    height:100px;
    border-radius: 50%;
    background-color: #865945;
    font-size: 60px;
    margin: auto;
    margin-top: -50px;
  }
  .sec5_button{
    font-size: 25px;
    border-radius: 5px;
    border-style: solid;
    border-color: #865845 ;
  background-color:#865845 ;
  
  }
  .hidden_text{
    visibility: hidden;
  }
  .sec6_text{
   position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #865845;
  }
.sec6_home_text li{
color:#865845 ;
font-size: 15px;
font-weight: bold;
}
.sec6_home_text span{
  color:#000000 ;
  
  }
  .sec6_line{
    position: absolute;
    right: 6px;
    width: 2px;
    height: 50px;
    background-color: #865845;
  }
  .sec-7_bg{
    background-image: url("../../photos/Background\ \(1\).png");
    background-size:100% 100%;
    background-repeat: no-repeat;
  
  }
  .sec7_input{
    display: block;
    width: 100%;
    height: 50px;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 2px;
    border-color: white;
    border-radius: 6px;
  }
  .sec7_input_message{
    display: block;
    width: 100%;
    height: 70px;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 2px;
    border-color: white;
    border-radius: 6px;
  }
  .sec7_input::placeholder{
  padding-left: 10px;
  color: white;
  
  }
  .sec7_input_message::placeholder{
    color: white;
  }
  .sec7_btn{
    background-color: #DFE0DF;
    color:#865945 ;
    border-radius: 8px;
    font-size: 36px;
    border-style: solid;
    border-width: 0px;
  }
  .sec8_bg{
    background-color: #865945;
  
  }
  .input-group-append{
    position: absolute;
    right: 25px;
    top: 5px;
  }
  .input-group-append_ar{
    position: absolute;
    left: 25px;
    top: 5px;
  }
  
  
}
@media  screen and (max-width: 1000px) {
  
  /* .sec1_bg_ar{

    background: url(../../photos/Background_ar.png)  no-repeat  fixed ;
    background-size: 120% 90%;
  }
   */
   .sec1_bg2_ar{
    /* padding-bottom: 70px;
    background:url(../../photos/Rectangle_bg2.png) no-repeat center center;
    background-size: 100% 100%; */
    padding-bottom: 70px;
  background-color: #865845;
  }
  /* .sec1_bg{
  

  background: url(../../photos/home_bg2.png)  no-repeat  fixed ;
  
  background-size: 120% 90%;
  
  } */
  
  .sec1_bg2{
    /* padding-bottom: 70px;
    background:url(../../photos/Rectangle_bg2.png) no-repeat center center;
    
    background-size: 100% 100%; */
    padding-bottom: 70px;
    background-color: #865845;
  }
  .sec1_home_text_center{
    justify-content: center;
    text-align: center;
    margin-inline-start: 0px;
    padding-top: 50px;
  }
}
@media  screen and (min-width: 999px) {
  
  .sec1_bg_ar{
    height: 900px;
  background: url(../../photos/Background_ar.png)  no-repeat center center fixed ;
  height: 100vh;
  
  background-size: 100% 100%;
  }
  .sec1_bg2_ar{
    background:url(../../photos/Rectangle\ 2_ar.png) no-repeat;
    background-position: right;
    background-size: 50% 100%;
    height: 100vh;
  }
  .sec1_bg{
  
    height: 100vh;
  background: url(../../photos//Background.png)  no-repeat center center fixed ;
  
  background-size: 100% 100%;
  
  }
  
  .sec1_bg2{
    background:url(../../photos/Rectangle\ 2.png) no-repeat;
    height: 100vh;
    background-size: 50% 100%;
  }
}

