
@media screen and (max-width: 1140px) {
    .About_fs{
        font-size: 40px;
    }
    .About_bg{
        background-image: url("../../photos/BACKGROUND\ \(2\).png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 400px;
    }


.about_img{
    width: 350px;
    display: flex;
    justify-content: center;
}
.about_text1{
    text-align: center;
    }
.about_text{
text-align: center;
}
.about_hidden{
    display: none;
}
.about_show{
    margin-top: 20px;
    display: flex;
}
  }

  @media screen and (min-width: 1139px) {
    .About_bg{
        background-image: url("../../photos/BACKGROUND\ \(2\).png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 100vh;
    }
    .About_fs{
        font-size: 100px;
    }

    .about_img{
        width: 400px;
    }
    .about_show{
    display: none;
    }
    .about_text1{
        margin-top: 40px;
        margin-inline-start: 50px;
    }
  }  

